import React, { HTMLProps, KeyboardEvent, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Button from "~/components/button";


interface Metadata {
  date?: string | null;
  description?: string | null;
  image?: string | null;
  title?: string | null;
  url?: string;
}

interface Edge {
  node: Metadata
}

const formatDate = (date: string | null | undefined): string => {
  if (!date) return "";

  const d: Date = new Date(date);

  const day: number = d.getDate();
  const month: number = d.getMonth() + 1;
  const year: number = d.getFullYear();

  return `${month}/${day}/${year}`;
};

const Body = ({ date, description, title }: Metadata): JSX.Element => {
  return (
    <div className="w-full">
      <div>
        <h3 className="mt-2 mb-0 text-gray-600 font-bold w-full text-sm h-14">{title || ""}</h3>
        <p className="mt-2 w-full h-6 text-indigo-600 font-bold text-tn">{formatDate(date) || ""}</p>
        <hr className="border-b border-gray-200 mt-2 min-w-full self-end flex" />
      </div>
      <p className="mt-2 w-full text-gray-600 text-sm">{description ? description.substring(0, 200) + " ..." : ""}</p>
    </div>
  );
};

const Footer = ({ url }: Metadata): JSX.Element => {
  return (
    <div className="flex self-end flex-grow justify-end w-full mt-2">
      <Button
        action="primary"
        className="mt-2 w-48"
        color="blue"
        href={url}
        icon="external"
        title="Read article"
      >
        Read
      </Button>
    </div>
  );
};

const Image = ({ image, url }: Metadata): JSX.Element => {
  return (
    <a tabIndex={-1} className="overflow-hidden w-full" style={{ transform: "translateZ(0)" }} href={url} target="_blank">
      <img className="h-52 w-full rounded-t-lg hover:scale-110 transition-all ease-in-out duration-300" src={image || ""} alt="Article metadata image" />
    </a>
  );
};

const NewsItemWrapper = ({ children }: HTMLProps<HTMLDivElement>): JSX.Element => {
  return (
    <div className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden" style={{ flex: "0 0 20rem" }}>
      {children}
    </div>
  );
};

const NewsItem = ({ date, description, image, title, url }: Metadata): JSX.Element => {
  return (
    <NewsItemWrapper>
      <Image url={url} image={image} />
      <div className="flex flex-grow flex-wrap flex-column p-3">
        <Body date={date} description={description} title={title} />
        <Footer url={url} />
      </div>
    </NewsItemWrapper>
  );
};

const edge = (edge: Edge, index: number): JSX.Element => {
  const metadata: Metadata = {
    date: edge.node.date,
    description: edge.node.description,
    image: edge.node.image,
    title: edge.node.title,
    url: edge.node.url
  };

  return (
    <NewsItem
      key={`link-${index}`}
      {...metadata}
    />
  );
};

const News = ({ className }: HTMLProps<HTMLElement>): JSX.Element => {
  const divRef = useRef<HTMLDivElement>(null);

  const data = useStaticQuery(graphql`
    query NewsItems {
      allNews {
        edges {
          node {
            date
            description
            image
            title
            url
          }
        }
      }
    }
  `);

  const edges = data.allNews.edges;

  const scrollLeft = () => {
    if (divRef?.current) {
      divRef.current.scrollLeft -= 10;
    }
  }

  const scrollRight = () => {
    if (divRef?.current) {
      divRef.current.scrollLeft += 10;
    }
  }

  let leftInt: NodeJS.Timeout;
  let rightInt: NodeJS.Timeout;

  const ScrollIndicator = (): JSX.Element => {
    return (
      <div className="flex justify-center w-full mt-4">
        <div className="text-lg mx-auto text-indigo-600 hover:text-indigo-800 transition-color duration-300 ease-in-out">
          <button
            aria-label="left-arrow"
            className="outline-none"
            onMouseDown={() => leftInt = setInterval(scrollLeft, 10)}
            onMouseUp={() => clearInterval(leftInt)}
            tabIndex={-1}
          >
              <i className="fad fa-arrow-left text-indigo-600 hover:text-indigo-800 hover:text-xl transition-all duration-300 ease-in-out"/>
          </button>
          &nbsp;Scroll&nbsp;
          <button
            aria-label="right-arrow"
            className="outline-none"
            onMouseDown={() => rightInt = setInterval(scrollRight, 10)}
            onMouseUp={() => clearInterval(rightInt)}
            tabIndex={-1}
          >
            <i className="fad fa-arrow-right text-indigo-600 hover:text-indigo-800 hover:text-xl transition-all duration-300 ease-in-out" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <section
      className={className ? `outline-none ${className}` : "outline-none"}
      tabIndex={0}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (e.repeat) return;

        if (e.key === "ArrowLeft" || e.key === "Left") {
          clearInterval(rightInt);
          leftInt = setInterval(scrollLeft, 10)
        }
        if (e.key === "ArrowRight" || e.key === "Right") {
          clearInterval(leftInt);
          rightInt = setInterval(scrollRight, 10)
        }
      }}
      onKeyUp={() => {
        clearInterval(leftInt);
        clearInterval(rightInt);
      }}
    >
      <h2 className="text-indigo-900 text-2xl font-semibold mt-6 leading-10 after:w-8 after:bg-indigo-900 after:h-1 after:block after:rounded">Science and Health Literacy in the News</h2>
      <ScrollIndicator />
      <div
        className="flex space-x-3 overflow-y-hidden overflow-x-auto mt-2 p-2"
        ref={divRef}
      >
        {edges.map(edge)}
      </div>
    </section>
  );
};

export default News;
