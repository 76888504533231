import React, { HTMLProps, useState, Dispatch, SetStateAction } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';

import Accordion from "~/components/accordion";
import Button from "~/components/button";
import News from "~/components/news";
import SEO from "~/components/seo";
import Unordered from "~/components/List/Unordered";
import UnorderedList from "~/components/List/Unordered";
import { JoinClassForm } from "~/components/forms";

import BSCSLogo from '~/icons/bscs.svg';
import NavigatorSVG from "~/icons/navigator-stepper-index.svg";
import SEPALogo from '~/icons/sepa.svg'

import "@fontsource/roboto";


interface HeaderProps {
  title1: string;
  title2: string;
}

interface CircleSectionProps {
  setSelected: Dispatch<SetStateAction<number>>;
  description?: any
}

const Flex = ({ children, className, ...rest }: HTMLProps<HTMLDivElement>): JSX.Element => {
  const classList = className
    ? `flex flex-col lg:flex-row ${className}`
    : "flex flex-col lg:flex-row";

  return (
    <div className={classList} {...rest}>
      {children}
    </div>
  );
};


const Header = ({ title1, title2 }: HeaderProps): JSX.Element => {
  return (
    <div className="flex bg-purple-900 shadow-lg overflow-hidden relative border-b-8 border-landing-page-lime-green">
      <div className="w-full px-10 py-5 md:py-10 tracking-wider z-30 lg:mr-10 xl:w-4/5">
        <h1 className="text-white text-4xl font-bold flex justify-start" style={{ fontFamily: "Roboto" }}>{title1}</h1>
        <h2 className="text-white text-4xl font-bold pt-4 flex mt-0 xl:justify-center" style={{ fontFamily: "Roboto" }}>{title2}</h2>
      </div>
      <img src={NavigatorSVG} alt="icon of school" className="absolute hidden lg:block top-0 right-0 z-20 h-full xl:left-auto" />
      <div className="bg-indigo-500 w-32 h-96 md:w-96 z-10 -skew-x-12 transform absolute md:right-0 -right-2">
        <div></div>
      </div>
    </div>
  );
};

const Body = ({ children }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="bg-gray-100 pb-10">
      {children}
    </div>
  );
};

const Footer = ({ children }: HTMLProps<HTMLElement>): JSX.Element => {
  return (
    <footer className="flex flex-wrap lg:flex-nowrap flex-col xl:flex-row">
      {children}
    </footer>
  );
};

const SocialMediaSection = (): JSX.Element => {
  return (
    <div className="flex space-x-12 w-full text-xl p-5">
      <a
        aria-label="mail-link"
        href="mailto:info@bscs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fas fa-paper-plane" />
      </a>
      <a
        aria-label="twitter-link"
        href="https://twitter.com/BSCSorg"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-twitter" />
      </a>
      <a
        aria-label="linkedin"
        href="https://www.linkedin.com/company/bscs"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-linkedin-in" />
      </a>
      <a
        aria-label="facebook"
        href="https://www.facebook.com/BSCSORG/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-facebook" />
      </a>
    </div>
  );
};

const AddressSection = (): JSX.Element => {
  return (
    <div className="p-5 lg:mt-5 text-left text-white leading-tight">
      <a href="https://goo.gl/maps/y5uT65epu1E2" target="_blank" rel="noopener noreferrer">
        <div>
          5415 Mark Dabling Blvd.&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup><br />
          Colorado Springs, CO 80918
        </div>
      </a>
      <a href="tel:719-531-5550">
        <div className="mt-4">
          719-531-5550&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
          <br />
        </div>
      </a>
      <a href="mailto:info@bscs.org">
        <div>
          info@bscs.org&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
          <br />
        </div>
      </a>
    </div>
  );
}

Footer.Purple = (): JSX.Element => {
  return (
    <div className="bg-purple-900 w-full xl:w-2/5 flex flex-col md:flex-row items-center justify-center lg:justify-around">
      <div className="text-white mt-5">
        <Link to="/" title="Homepage">
          <img src={BSCSLogo} alt="icon of BSCS" className="w-52 xl:w-56 mx-4 mt-4"/>
        </Link>
        <SocialMediaSection />
      </div>
      <AddressSection />
    </div>
  );
};

Footer.White = (): JSX.Element => {
  return (
    <div className="bg-indigo-500 text-white px-5 py-3 w-full xl:w-3/5">
      <div className="text-sm mt-6 flex flex-col xl:flex-row">
        <p>This project was supported by the Office of the Director, National Institutes of Health under Award Number R25OD020208. The content is solely the responsibility of the authors and does not necessarily represent the official views of the National Institutes of Health.</p>
        <img src={SEPALogo} alt="icon of SEPA" className="bg-white w-60 h-20 lg:ml-3 mt-4 lg:mt-0"/>
      </div>
      <p className="text-sm mt-5 float-right">Material & content copyright © 2022 BSCS Science Learning. All rights reserved.</p>
    </div>
  );
};

const Intro = (): JSX.Element => {
  return (
    <div className="bg-gray-100 w-full p-10 flex flex-col lg:flex-row">
      <div className="text-gray-900 text-lg">
        <p>Every day, people get information about health and science from the internet, television, social media, magazines, radio, and other media. The amount of information can, at times, be overwhelming. It can be difficult to know what is accurate and trustworthy.</p>
        <p className="mt-5 md:mb-8 xl:mb-0">Making Evidence-Driven Decisions in a Media-Driven World is a set of curriculum modules—one for middle school and one for high school students—that help students develop the critical thinking skills to help them make sense of information from these different media sources. The lessons draw on aspects of health literacy, science literacy, and media literacy. With these skills, students will be better prepared to make decisions about issues that affect their health and other areas of their lives.</p>
      </div>
      <div className="mt-5">
        <StaticImage
          alt="Students having a discussion."
          className="w-full rounded"
          src="../images/landing-page-phone.jpg"
          loading="eager"
          placeholder="blurred"
          style={{
            filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
          }}
        />
      </div>
    </div>
  );
};

const GetStarted = (): JSX.Element => {
  return (
    <section className="flex flex-col flex-wrap sm:px-10 pt-3 pb-5 w-full mt-5">
      <div>
        <h2 className="text-indigo-900 text-3xl font-bold mt-2 leading-10 px-6 md:px-10">Let's Get Started!</h2>
        <div className="max-w-full flex flex-col lg:flex-row my-7 mx-2 md:mx-10">
          <div
            className="flex flex-wrap justify-center content-center bg-purple-900 rounded-lg p-10 lg:w-1/2 lg:mr-2 mt-4 lg:mt-0"
            style={{
              filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
            }}
          >
            <h2 className="w-full my-0 text-white font-bold text-center">Student Sign In</h2>
            <div className="mt-5">
              <JoinClassForm background="dark" />
            </div>
          </div>
          <div
            className="bg-indigo-500 rounded-lg flex flex-wrap flex-col p-10 lg:w-1/2 lg:ml-2 mt-6 lg:mt-0 max-w-full"
            style={{
              filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
            }}
          >
            <h2 className="text-2xl font-semibold leading-10 text-white text-center my-0">Teacher Sign In</h2>
            <div className="flex flex-col md:w-1/2  lg:w-full xl:w-3/4 content-center mx-auto">
              <p className="mt-5 text-white font-medium text-lg flex justify-center">Sign in or register to create classes for your students!</p>
              <div className="mt-9 space-y-3 md:space-y-0 md:space-x-4 flex flex-col md:flex-row mx-auto lg:mx-0 md:justify-start">
                <Button
                  action="primary"
                  color="blue"
                  href="/sign-in"
                  style={{ width: "152px" }}
                  title="Sign in"
                >
                  Sign in
                </Button>
                <Button
                  action="primary"
                  className="text-indigo-800"
                  color="white"
                  href="/sign-up"
                  style={{ width: "152px" }}
                  title="Register"
                >
                  Register
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 lg:w-3/4 mx-3 sm:mx-auto">
        <StaticImage
          alt="Students having a discussion."
          className="w-full rounded"
          src="../images/landing-page-students.jpg"
          loading="eager"
          placeholder="blurred"
          style={{
            filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
          }}
        />
      </div>
    </section>
  );
};

const HealthLiteracy = () => {
  return (
    <Accordion open={true} headerText="What is health literacy?">
      <div className="max-w-full">
        <p><span className="font-semibold">Health Literacy</span> can be defined as the degree to which an individual has the capacity to obtain, communicate, process, and understand basic health information and services to make appropriate health decisions.</p>

        <p className="mt-4 flex flex-wrap">Reference: Centers for Disease Control and Prevention (2019) What is health literacy?
        <Button
          action="tertiary"
          className="pl-0"
          color="indigo"
          href="https://www.cdc.gov/healthliteracy/learn"
          icon="external"
          title="CDC Health Literacy"
        >
          https://www.cdc.gov/healthliteracy/learn
        </Button>
      </p>
    </div>
  </Accordion>
  );
};

const HealthLiteracyClosed = () => {
  return (
    <Accordion open={false} headerText="What is health literacy?">
      <div>
        <p><span className="font-semibold">Health Literacy</span> can be defined as the degree to which an individual has the capacity to obtain, communicate, process, and understand basic health information and services to make appropriate health decisions.</p>

        <p className="mt-4 flex flex-wrap">Reference: Centers for Disease Control and Prevention (2019) What is health literacy?
        <Button
          action="tertiary"
          className="pl-0"
          color="indigo"
          href="https://www.cdc.gov/healthliteracy/learn"
          icon="external"
          title="CDC Health Literacy"
        >
          https://www.cdc.gov/healthliteracy/learn
        </Button>
      </p>
    </div>
  </Accordion>
  );
};

const MediaLiteracy = () => {
  return (
    <Accordion open={true} headerText="What is media literacy?" className="mt-5">
      <div>
        <p><span className="font-semibold">Media literacy</span> is the ability to access, analyze, evaluate, and create media in a variety of forms.</p>

        <p className="mt-4">Media literacy provides a framework to access, analyze, evaluate, create, and participate with messages in a variety of forms—from print to video to the internet. Media literacy builds an understanding of the role of media in society as well as essential skills of inquiry and self-expression necessary for citizens of a democracy.
        </p>
        <p className="mt-4 flex flex-wrap">Reference: Center for Media Literacy. Media literacy: A definition and more.
          <Button
            action="tertiary"
            className="pl-0"
            color="indigo"
            href="http://www.medialit.org/media-literacy-definition-and-more"
            icon="external"
            title="medialit.org"
          >
            http://www.medialit.org/media-literacy-definition-and-more
          </Button>
        </p>
      </div>
    </Accordion>
  );
};

const MediaLiteracyClosed = () => {
  return (
    <Accordion open={false} headerText="What is media literacy?" className="mt-5">
      <div>
        <p><span className="font-semibold">Media literacy</span> is the ability to access, analyze, evaluate, and create media in a variety of forms.</p>

        <p className="mt-4">Media literacy provides a framework to access, analyze, evaluate, create, and participate with messages in a variety of forms—from print to video to the internet. Media literacy builds an understanding of the role of media in society as well as essential skills of inquiry and self-expression necessary for citizens of a democracy.
        </p>
        <p className="mt-4 flex flex-wrap">Reference: Center for Media Literacy. Media literacy: A definition and more.
          <Button
            action="tertiary"
            className="pl-0"
            color="indigo"
            href="http://www.medialit.org/media-literacy-definition-and-more"
            icon="external"
            title="medialit.org"
          >
            http://www.medialit.org/media-literacy-definition-and-more
          </Button>
        </p>
      </div>
    </Accordion>
  );
};

const ScienceLiteracy = () => {
  return (
    <Accordion open={true} headerText="What is science literacy?" className="mt-5">
      <div className="max-w-full">
        <p><strong className="font-semibold">Science Literacy</strong> refers to an individual's:</p>

        <UnorderedList>
          <li>scientific knowledge and use of that knowledge to identify questions, acquire new knowledge, explain scientific phenomena, and draw evidence-based conclusions about science-related issues.</li>
          <li>understanding of the characteristic features of science as a form of human knowledge and enquiry.</li>
          <li>awareness of how science and technology shape our material, intellectual and cultural environments.</li>
          <li>willingness to engage in science-related issues, and with the ideas of science, as a reflective citizen.</li>
        </UnorderedList>

        <p className="mt-4 flex flex-wrap">Reference: OECD (2013). PISA 2012 Assessment and Analytical Framework: Mathematics, Reading, Science, Problem Solving and Financial Literacy, OECD Publishing.
          <Button
            action="tertiary"
            className="pl-0"
            color="indigo"
            href="http://dx.doi.org/10.1787/9789264190511-en"
            icon="external"
            title="PISA 2012 Assessment and Analytical Framework"
          >
            http://dx.doi.org/10.1787/9789264190511-en
          </Button>
        </p>
      </div>
    </Accordion>
  );
};

const ScienceLiteracyClosed = () => {
  return (
    <Accordion open={false} headerText="What is science literacy?" className="mt-5">
      <div>
        <p><strong className="font-semibold">Science Literacy</strong> refers to an individual's:</p>

        <UnorderedList>
          <li>scientific knowledge and use of that knowledge to identify questions, acquire new knowledge, explain scientific phenomena, and draw evidence-based conclusions about science-related issues.</li>
          <li>understanding of the characteristic features of science as a form of human knowledge and enquiry.</li>
          <li>awareness of how science and technology shape our material, intellectual and cultural environments.</li>
          <li>willingness to engage in science-related issues, and with the ideas of science, as a reflective citizen.</li>
        </UnorderedList>

        <p className="mt-4 flex flex-wrap">Reference: OECD (2013). PISA 2012 Assessment and Analytical Framework: Mathematics, Reading, Science, Problem Solving and Financial Literacy, OECD Publishing.
          <Button
            action="tertiary"
            className="pl-0"
            color="indigo"
            href="http://dx.doi.org/10.1787/9789264190511-en"
            icon="external"
            title="PISA 2012 Assessment and Analytical Framework"
          >
            http://dx.doi.org/10.1787/9789264190511-en
          </Button>
        </p>
      </div>
    </Accordion>
  );
};

const HealthLiteracyLinkText = ( ): JSX.Element => {
  return (
    <div className="text-gray-900 px-10 pt-10">
      <h2 className="text-indigo-900 text-2xl font-semibold mt-6 leading-10 after:w-8 after:bg-indigo-900 after:h-1 after:block after:rounded">How Does Health Literacy Link to Science and Media Literacy?</h2>
      <p className="my-6 text-lg">
        Making Evidence-Driven Decisions in a Media-Driven World brings together aspects of health, science, and media literacy. In particular, the lessons in these modules focus on helping students develop the skills of obtaining, analyzing, and understanding information. Although the lessons rely on health-related examples, the skills that students learn apply to many areas of life.
      </p>
    </div>
  );
};

const FAQs = (): JSX.Element => {
  return (
    <section className="my-14 px-10 max-w-5xl">
      <h2 className="text-indigo-900 text-2xl font-semibold my-6 leading-10 after:w-8 after:bg-indigo-900 after:h-1 after:block after:rounded">FAQs About Health Literacy</h2>
      <Accordion headerText="Why is health literacy important?" open={false}>
        <div className="max-w-full">
          <p>Virtually every day, we hear about new products or treatments that are supposed to help improve our lives. We get this information from TV, the internet, magazines, and even through social media. Have you heard people ask questions similar to the following:</p>

          <div className="mt-5">
            <Unordered>
              <li>Why does it take a long time to develop a vaccine?</li>
              <li>How do viruses spread?</li>
              <li>People on the news say different things. How do you know what is right?</li>
              <li>Will the product advertised on TV work for me?</li>
              <li>Why do people need to get a flu shot every year?</li>
              <li>If it's natural, it's safe, isn't it?</li>
              <li>Eggs are bad. Eggs are OK. Why do they keep changing their minds?</li>
            </Unordered>
          </div>

          <p className="mt-5">Sometimes it is hard to know what to believe. Sometimes, things may seem too good to be true, but at the same time, seems to be presented in a very believable way. Information in the media is often tries to persuade people in a certain way. For example, ads may use jargon or buzzwords to influence people rather than present scientific evidence. An important skill for everyone is to ask questions to get a more complete answer. If people know how to obtain and analyze health-related information, they are likely to make better decisions.</p>
        </div>
      </Accordion>
      <Accordion headerText="How do health literacy skills help people?" open={false} className="mt-5">
        <div className="max-w-full">
          <p>People who are health literate are better able to:</p>

          <div className="mt-5">
            <Unordered>
              <li>understand written health information from the Internet, television, newspapers, brochures, and other sources</li>
              <li>make decisions about participating in research studies</li>
              <li>ask questions about benefits and risks of health care treatments</li>
              <li>find high-quality information about health-related issues or treatments</li>
              <li>understand directions for taking medications correctly</li>
              <li>answer questions about their health for their doctors or insurance companies</li>
              <li>compare information from different sources</li>
            </Unordered>
          </div>
        </div>
      </Accordion>
      <Accordion headerText="Why should people become health literate?" open={false} className="mt-5">
        <div className="max-w-full">
          <Unordered>
            <li>Over a third of U.S. adults—77 million people—would have difficulty with common health tasks, such as following directions on a prescription drug label or adhering to a childhood immunization schedule using a standard chart</li>
            <li>Poor health literacy may be a stronger predictor of health than age, income, employment status, education level, and race.</li>
            <li>People with limited health literacy are more likely to skip medical tests and experience more emergency room visits.</li>
            <li>The cost of low health literacy to the U.S. economy may be as high as $600 billion annually.</li>
          </Unordered>
        </div>
      </Accordion>

      <Accordion headerText="Why is health literacy important for teens and adolescents?" open={false} className="mt-5">
        <div className="max-w-full">
          <Unordered>
            <li>Health behaviors developed during adolescence impact the risk for chronic diseases as adults.</li>
            <li>Individuals with adequate or high levels of health literacy enjoy better overall health outcomes than those with limited health literacy.</li>
            <li>Demographic changes among adolescents in the U.S. are predicted to lead to an increase in health disparities and lower health literacy.</li>
            <li>Adolescents generally do not have a high level of health literacy and often face difficulties understanding health information that is presented to them online.</li>
            <li>Adolescents and adults increasingly use the internet to find information about health issues. As many as 70 percent of health-related websites include errors or omissions in their information.</li>
          </Unordered>
        </div>
      </Accordion>
    </section>
  );
};

const getDescription = (selected: number) => {
  const descriptions: (() => JSX.Element)[] = [HealthLiteracy, MediaLiteracy, ScienceLiteracy];
  if (selected >= descriptions.length) {
    return null
  };

  const LiteracyTerm: () => JSX.Element = descriptions[selected];

  return (
    <div>
      { LiteracyTerm == descriptions[0]
        ? <HealthLiteracy />
        : <HealthLiteracyClosed />
      }
      { LiteracyTerm == descriptions[1]
        ? <MediaLiteracy />
        : <MediaLiteracyClosed />
      }
      { LiteracyTerm == descriptions[2]
        ? <ScienceLiteracy />
        : <ScienceLiteracyClosed />
      }
    </div>
  );
};

const CircleSection = ({  }: CircleSectionProps) => {
  const [selected, setSelected] = useState<number>(0);

  return (
    <section className="bg-gray-100 p-10">
      <h3 className="font-semibold">Click on each circle to see a definition for each type of literacy.</h3>
      <div className="flex flex-col lg:flex-row">
        <div className="md:w-384 lg:w-512 xl:w-640 mt-8 sm:mr-20">
          <button
            className="rounded-full h-48 w-48 flex flex-col items-center justify-center bg-center bg-landing-page-yellow bg-opacity-30 ml-10 text-sm sm:h-60 sm:ml-28 sm:w-60 sm:mx-auto sm:text-base lg:ml-46 font-bold"
            onClick={() => setSelected(0)}
            >
            <span>Health Literacy</span>
            <i className="fad fa-book-medical text-2xl mt-2" />
          </button>
          <button
            className="rounded-full h-48 w-48 sm:h-60 sm:w-60 flex flex-col items-center justify-center justify-items-center bg-landing-page-green bg-opacity-30 pt-4 sm:pl-4 sm:ml-52 sm:-mt-24 -mt-20 ml-32 lg:ml-66 text-sm sm:text-base font-bold"  onClick={() => setSelected(1)}>
            <span>Media Literacy</span>
            <i className="fad fa-tv-retro text-2xl mt-2" />
          </button>
          <button
            className="rounded-full h-48 w-48 sm:h-60 sm:w-60 flex flex-col items-center justify-center bg-indigo-500 bg-opacity-30 pt-4 font-bold sm:pr-4 2xl:pr-16 -mt-48 sm:-mt-60 -ml-8 sm:ml-2 lg:ml-18 text-sm sm:text-base"
            onClick={() => setSelected(2)}
            >
            <span>Science Literacy</span>
            <i className="fad fa-flask text-2xl mt-2" />
          </button>
        </div>
        <div className="mt-10 w-full">
          {getDescription(selected)}
        </div>
      </div>
    </section>
  );
};

const Index = (): JSX.Element => {

  const [selected, setSelected] = useState<number>(0);

  const title: string = "Making Evidence-Driven Decisions in a Media-Driven World";
  const title1: string = "Making Evidence-Driven Decisions";
  const title2: string = "in a Media-Driven World";
  return (
    <div className="flex flex-col overflow-x-hidden">
      <SEO title={title} />
      <Header title1={title1} title2={title2} />
      <Intro />
      <Body>
        <GetStarted />
        <HealthLiteracyLinkText />
        <CircleSection setSelected={setSelected} description={selected} />
        <FAQs />
        <News className="mt-16 px-10" />
      </Body>
      <Footer>
        <Footer.Purple />
        <Footer.White />
      </Footer>
    </div>
  );
};

export default Index;